.profile {
    background: rgb(251, 245, 239);
    padding: 0px;
    margin: auto;

    .form-group {
        width: 800px;
    }

    label {
        width: 200px;
        font-weight: 700;
        color: rgb(58, 57, 57);
    }

    .error {
        color: red;
    }

    .btn-primary {
        border: none;
        width: 100px;
        border-radius: 12px;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
    }
}

.fieldset {
    border: 2px solid #f5f1de;
    padding:10px 40px; 
    background:#f8f7f6;
    // width:300px;
    border-radius:25px;
    margin-bottom: 20px;
}